import React from 'react'
import styled from 'styled-components'
import DifferentiatorsServiceData from '../Data/DifferentiatorsServiceData.json'
import { Block } from './Block'
// import Scroll from 'react-scroll'
// var scroll = Scroll.animateScroll

const AppServiceDifferentiatorsWpper = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  .DifferentiatorsContain {
    max-width: 1920px;
    margin: auto;
    background-color: #62a6c6c4;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .DifferentiatorsSection {
    display: flex;
    justify-content: space-around;
    @media (max-width: 1090px) {
      flex-flow: wrap;
    }
  }
  /* .DifferentiatorsHeading {
    text-align: left;
    margin-top: 10%;
    padding-left: 15%;
    @media (max-width: 1090px) {
      text-align: center;
      margin-top: 0%;
      padding-left: 0%;
    }
  } */
  .DifferentiatorsHeadingSmall {
    display: none;
    @media (max-width: 1090px) {
      display: block;
      text-align: center;
      padding-bottom: 2%;
    }
  }
  .DifferentiatorsHeadingBig {
    color: #fff;
    padding: 0px 0px 5% 50px;
    @media (max-width: 1090px) {
      display: none;
    }
  }
  .DifferentiatorsImage {
    height: auto;
    width: 60%;
    order: 1;
    @media (max-width: 1090px) {
      order: 0;
      width: 100%;
      margin-top: 0%;
    }
  }
  .DifferentiatorsImageCenter {
    width: 90%;
    display: flex;
    float: right;
    @media (max-width: 1090px) {
      width: 100%;
      float: unset;
    }
  }
  .DifferentiatorsParaSection {
    order: 0;
    margin-top: auto;
    margin-bottom: auto;
    @media (max-width: 1090px) {
      order: 1;
    }
  }
  .DifferentiatorsPara {
    max-width: 600px;
    color: #fff;
    padding: 0px 0px 15px 50px;
    @media (max-width: 1090px) {
      max-width: 825px;
      padding: unset;
    }
    @media (max-width: 420px) {
      padding-left: 25px;
    }
  }
  .DifferentiatorsParaNone {
    display: none;
  }
  .buttonDisplay {
    display: none;
    justify-content: center;
    padding: 5%;
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px;
  }
  .PonitPara {
    margin-top: -15%;
  }
`

export const AppServiceDifferentiators = () => {
  // const sameScrollTo = (str: string) => {
  //   if (str === 'Differentiators') {
  //     scroll.scrollTo(1000)
  //   }
  //   if (str === 'Differentiators') {
  //     scroll.scrollTo(1000)
  //   }
  //   if (str === 'Differentiators') {
  //     scroll.scrollTo(1000)
  //   }
  //   if (str === 'Differentiators') {
  //     scroll.scrollTo(1000)
  //   }
  // }
  return (
    <AppServiceDifferentiatorsWpper>
      {DifferentiatorsServiceData.map((data, index) => {
        return (
          <div key={index} id={data.id}>
            <Block
              Heading={data.Title}
              paraOne={data.Para}
              ImagePath={data.ImagePath}
              mainContain='DifferentiatorsContain'
              containHeading='DifferentiatorsHeadingSmall'
              containHeadingBig='DifferentiatorsHeadingBig'
              containParaOne='DifferentiatorsPara'
              containParaTwo='DifferentiatorsParaNone'
              containParaThree='DifferentiatorsParaNone'
              InternalSection='DifferentiatorsSection'
              containImage='DifferentiatorsImage'
              altName={data.AltName}
              paraSection='DifferentiatorsParaSection'
              ButtonDisplay='buttonDisplay'
              allImage='DifferentiatorsImageCenter'
              // onClick={sameScrollTo}
            />
          </div>
        )
      })}
    </AppServiceDifferentiatorsWpper>
  )
}
